<script>
import { mapGetters } from 'vuex'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { dateFilter } from 'vue-date-fns'

import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import addDays from 'date-fns/addDays'
import add from 'date-fns/add'
import startOfMonth from 'date-fns/startOfMonth'
import addMonths from 'date-fns/addMonths'
import endOfMonth from 'date-fns/endOfMonth'
import getMonth from 'date-fns/getMonth'
import getYear from 'date-fns/getYear'
import format from 'date-fns/format'
import ApiService from '@/core/services/api.service'

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import axios from 'axios'

// import { dateFilter } from 'vue-date-fns'
// import DatePicker from 'v-calendar/lib/components/date-picker.umd'
// import format from 'date-fns/format'

export default {
  name: 'DepositsList2',
  filters: {
    date: dateFilter,
  },
  components: {
    DatePicker,
    vSelect,
  },
  data() {
    return {
      loading: false,
      total: 0,
      deposits: [],
      get_deposits: [],
      verify_deposits: [],
      selected_deposits: [],
      all_deposits_selected: false,
      devices: [],
      locations: [],
      selected_locations: [],
      user_id: null,
      company_id: null,
      search_query: null,
      filter_type_selected: { label: 'All', value: 'all' },
      filter_type_options: [
        { label: 'All', value: 'all' },
        { label: 'New', value: 'new' },
        { label: 'Not-Verified', value: 'not-verified' },
        { label: 'Verified', value: 'verified' },
      ],
      range: {
        start: null,
        end: null,
      },
      masks: {
        input: 'DD.MM. ',
      },
      attributes: [
        {
          key: 'today',
          highlight: true,
          dates: null,
        },
      ],
      reportAmountId: null,
      reportAmountKey: null,
      reportAmountValue: null,
      verifyDepositKey: null,
      verifyDepositObj: null,
      reportedTokenAmount: null,
    }
  },
  mounted() {
    this.company_id = this.currentUserCompany.replace('/api/v1/companies/', '')
    this.user_id = this.currentUserPersonalInfo.id

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Deposits', route: '/' },
      { title: 'Deposit List', route: '/' },
    ])
    this.last7Days()
    this.loadDeposits()
  },
  methods: {
    loadDeposits() {
      this.loading = true
      let params = {
        company_id: this.company_id,
        // user_id: this.user_id,
        date_from: format(this.range.start, 'yyyy-MM-dd'),
        date_to: format(this.range.end, 'yyyy-MM-dd'),
        pagination: false,
        new: 1,
      }
      if (this.currentUserRole === 'ROLE_JUNIOR') {
        params['user_id'] = this.user_id
      }
      switch (this.filter_type_selected.value) {
        case 'verified':
          params['is_verified'] = 1
          break
        case 'not-verified':
          params['is_verified'] = 0
          break
        case 'new':
          params['new_deposit'] = 1
          break
      }

      let url_params = new URLSearchParams(params).toString()
      this.loading = true

      ApiService.get('deposits', '?' + url_params).then(({ data }) => {
        this.get_deposits = data['hydra:member']
        this.total = data['hydra:totalItems']
        this.filterDeposits()
        this.loading = false
      })
    },
    filtersApply() {
      this.get_deposits = []
      this.loadDeposits()
    },
    filterDeposits() {
      let fDeps = this.get_deposits

      if (this.search_query != null && this.search_query !== '') {
        fDeps = fDeps.filter((item) => {
          return item.location.name
            .toUpperCase()
            .includes(this.search_query.toUpperCase())
        })
      }
      this.deposits = fDeps
      this.verify_deposits = fDeps.filter((item) => {
        return item.reportedAmount != null && !item.isVerified
      })
    },
    calcDiff(item) {
      if (!item.reportedAmount) {
        return '-'
      } else {
        let diff = parseFloat(item.reportedAmount) - parseFloat(item.amount)
        let label = 'primary'
        if (diff > 0) {
          label = 'success'
        } else if (diff < 0) {
          label = 'danger'
        }
        return `<span class="label label-light-${label} label-inline label-lg">${diff.toFixed(
          2
        )}</span>`
      }
    },
    reportAmountModal(id, key) {
      this.reportAmountId = id
      this.reportAmountKey = key
      this.reportAmountValue = this.deposits[key].reportedAmount || '0'
      this.reportedTokenAmount = this.deposits[key].reportedTokenAmount || '0'
      this.$root.$emit('bv::show::modal', 'change_reported_amount')
    },

    reportAmount() {
      ApiService.update('deposits', this.reportAmountId, {
        reportedAmount: this.reportAmountValue,
        reportedTokenAmount: Number(this.reportedTokenAmount),
        user: `/api/v1/users/${this.user_id}`,
      }).then(({ data }) => {
        this.deposits[this.reportAmountKey].reportedAmount = data.reportedAmount
        this.deposits[this.reportAmountKey].user.id = data.user.id
        this.deposits[this.reportAmountKey].user.name =
          data.user.name + ' ' + data.user.surname
        this.reportAmountId = null
        this.reportAmountKey = null
        this.reportAmountValue = null
        this.reportedTokenAmount = null
        this.filterDeposits()

        this.$root.$emit('bv::hide::modal', 'change_reported_amount')
        this.$notify({
          group: 'notification',
          type: 'success',
          title: this.$t('DEPOSITS.REPORT_SUCCESS'),
        })
      })
    },
    verifyDepositModal(key) {
      this.verifyDepositKey = key
      this.verifyDepositObj = this.deposits[key]
      this.$root.$emit('bv::show::modal', 'verify_amount')
    },
    verifyDeposit() {
      ApiService.update('deposits', this.deposits[this.verifyDepositKey].id, {
        isNotificationSend: true,
      }).then(() => {
        axios
          .get(
            `${
              process.env.VUE_APP_ROUTE_API_URL
            }/api/v1/deposits/send-verification-email/${
              this.currentUserCompanyOib
            }/${this.currentUserPersonalInfo.email}/${
              this.deposits[this.verifyDepositKey].id
            }/true`
          )
          .then(() => {
            this.deposits[this.verifyDepositKey].isVerified = true
            this.verifyDepositKey = null
            this.verifyDepositObj = null
            this.filterDeposits()

            this.$root.$emit('bv::hide::modal', 'verify_amount')
            this.$notify({
              group: 'notification',
              type: 'success',
              title: this.$t('DEPOSITS.VERIFICATION_SUCCESS'),
            })
          })
      })
    },
    selectAll(checked) {
      if (checked) {
        this.selected_deposits = this.verify_deposits.map(
          (deposit) => deposit.id
        )
      } else {
        this.selected_deposits = []
      }
    },
    selectSingle() {
      this.all_deposits_selected = false
    },
    verifyAllDepositModal() {
      this.$root.$emit('bv::show::modal', 'verify_all')
    },
    verifyAllDeposits() {
      ApiService.update('deposit/verify', null, {
        deposits: this.selected_deposits,
      }).then(() => {
        axios
          .get(
            `${process.env.VUE_APP_ROUTE_API_URL}/api/v1/deposits/send-verification-email/${this.currentUserCompanyOib}/${this.currentUserPersonalInfo.email}/all/false`
          )
          .then(() => {
            this.selected_deposits.forEach((item) => {
              this.get_deposits.find(
                (deposit) => deposit.id === item
              ).isVerified = true
            })
            this.selected_deposits = []
            this.all_deposits_selected = false
            this.filterDeposits()
            this.$notify({
              group: 'notification',
              type: 'success',
              title: this.$t('DEPOSITS.VERIFICATION_SUCCESS'),
            })
          })
      })

      this.$root.$emit('bv::hide::modal', 'verify_all')
    },
    setStartEndDate() {
      let a = addDays(this.range.start, -1)
      a.setHours(24, 0, 0)
      this.range.start = a

      let b = addDays(this.range.end, 0)
      b.setHours(23, 59, 59)
      this.range.end = b

      this.$refs.picker.updateValue({
        start: this.range.start,
        end: this.range.end,
      })
    },
    yesterday() {
      this.$refs.picker.updateValue({
        start: addDays(new Date(), -1),
        end: addDays(new Date(), -1),
      })
    },
    last7Days() {
      this.$refs.picker.updateValue({
        start: add(new Date(), { days: -7 }),
        end: add(new Date(), { days: null }),
      })
    },
    last30Days() {
      this.$refs.picker.updateValue({
        start: add(new Date(), { days: -30 }),
        end: add(new Date(), { days: null }),
      })
    },
    thisMonth() {
      this.$refs.picker.updateValue({
        start: startOfMonth(new Date()),
        end: new Date(),
      })
    },
    lastMonth() {
      this.$refs.picker.updateValue({ start: null, end: null })

      this.$refs.picker.updateValue({
        start: startOfMonth(addMonths(new Date(), -1)),
        end: endOfMonth(addMonths(new Date(), -1)),
      })

      this.$refs.picker.move({
        month: getMonth(addMonths(new Date(), -1)) + 1,
        year: getYear(addMonths(new Date(), -1)),
      })
    },
  },
  computed: {
    ...mapGetters([
      'currentUserRole',
      'currentUserPersonalInfo',
      'currentUserCompany',
      'currentUserCompanyOib',
    ]),
  },
}
</script>

<template>
  <div>
    <notifications group="notification" position="top right" />
    <div class="card card-custom gutter-b">
      <div class="card-header border-0 py-5">
        <h3 class="card-title align-items-center">
          <span class="card-label font-weight-bolder text-dark">
            {{ $t('MAIN_MENU.DEPOSITS') }}
          </span>
        </h3>
        <div class="card-toolbar"></div>
      </div>

      <div class="card-body py-0">
        <!--    FILTERS    -->

        <div class="row filters">
          <div class="col">
            <div class="form-group form-inline">
              <!--              <label class="font-weight-bolder">A Date Range</label>-->
              <DatePicker
                ref="picker"
                class="col"
                v-model="range"
                mode="date"
                :attributes="attributes"
                locale="hr"
                is-range
                :max-date="new Date()"
                :masks="masks"
              >
                <template v-slot="{ inputValue, togglePopover }">
                  <input
                    @click="togglePopover()"
                    :value="inputValue.start + ' - ' + inputValue.end"
                    class="form-control"
                    style="height: 42px"
                    readonly
                  />
                </template>
                <template v-slot:footer>
                  <!--                  <div class="row p-2 pr-0">-->
                  <!--                    <div class="col pl-5 pr-0">-->
                  <!--                      <div-->
                  <!--                          @click="setStartEndDate('range')"-->
                  <!--                          class="symbol symbol-40 symbol-light-primary"-->
                  <!--                      >-->
                  <!--                        <div class="symbol-label cursor-pointer">-->
                  <!--                              <span-->
                  <!--                                  class="svg-icon svg-icon-lx svg-icon-primary"-->
                  <!--                              >-->
                  <!--                                <inline-svg-->
                  <!--                                    v-b-tooltip.hover.top="'00:00-23:59'"-->
                  <!--                                    src="media/svg/icons/Home/Clock.svg"-->
                  <!--                                />-->
                  <!--                              </span>-->
                  <!--                        </div>-->
                  <!--                      </div>-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                  <div class="row p-2">
                    <div class="col pl-5 pr-0">
                      <div
                        @click="yesterday"
                        class="symbol symbol-40 symbol-light-primary"
                      >
                        <div
                          v-b-tooltip.hover.top="'Yesterday'"
                          class="symbol-label cursor-pointer"
                        >
                          Y
                        </div>
                      </div>
                    </div>
                    <div class="col p-0">
                      <div
                        @click="last7Days"
                        class="symbol symbol-40 symbol-light-primary"
                      >
                        <div
                          v-b-tooltip.hover.top="'-7 days'"
                          class="symbol-label cursor-pointer"
                        >
                          -7D
                        </div>
                      </div>
                    </div>
                    <div class="col p-0">
                      <div
                        @click="last30Days"
                        class="symbol symbol-40 symbol-light-primary"
                      >
                        <div
                          v-b-tooltip.hover.top="'-30 days'"
                          class="symbol-label cursor-pointer"
                        >
                          -30D
                        </div>
                      </div>
                    </div>
                    <div class="col p-0">
                      <div
                        @click="lastMonth"
                        class="symbol symbol-40 symbol-light-primary"
                      >
                        <div
                          v-b-tooltip.hover.top="'Last month'"
                          class="symbol-label cursor-pointer"
                        >
                          LM
                        </div>
                      </div>
                    </div>
                    <div class="col pl-0">
                      <div
                        @click="thisMonth"
                        class="symbol symbol-40 symbol-light-primary"
                      >
                        <div
                          v-b-tooltip.hover.top="'This month'"
                          class="symbol-label cursor-pointer"
                        >
                          TM
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </DatePicker>
            </div>
          </div>

          <div class="col-sm-12 col-md-12 col-xl mb-3 mb-xl-0">
            <div class="input-group">
              <input
                type="text"
                v-model="search_query"
                class="form-control form-control-solid form-control-lg"
                :placeholder="$t('DEPOSITS.LOCATION_SEARCH')"
              />
            </div>
          </div>

          <div class="col">
            <vSelect
              class="pt-2 pb-2"
              :options="filter_type_options"
              v-model="filter_type_selected"
            >
              <!--              <template #option="{ name }">
                              <span class="mr-3">{{ name }}</span>
                            </template>-->
            </vSelect>
          </div>

          <div class="col-sm-12 col-md-12 col-xl mb-3 mb-xl-0">
            <button
              @click="filtersApply"
              class="btn btn-light-primary btn-block"
              style="height: 44px"
            >
              Filter
            </button>
          </div>
        </div>
        <div class="separator separator-dashed my-5"></div>

        <div class="row text-left d-flex align-items-center my-5">
          <div class="col mb-4">
            {{ $t('GENERAL.RESULTS') }}: <b>{{ deposits.length }}</b>
            <span>
              | {{ $t('DEPOSITS.PENDING_VERIFICATION') }}:
              <b>{{ verify_deposits.length }}</b></span
            >
          </div>
          <b-button
            class="mr-4"
            @click="verifyAllDepositModal()"
            variant="primary"
            :disabled="selected_deposits.length === 0"
            >{{ $t('DEPOSITS.VERIFY_SELECTED') }}</b-button
          >
        </div>

        <!--    DATATABLE    -->
        <div class="row">
          <div class="col-md-12">
            <div class="table-responsive">
              <div
                v-if="loading === true"
                class="col-12 text-center my-5 p-2 pt-5 bg-secondary"
              >
                <div class="text-center my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-4">{{ $t('GENERAL.LOADING') }}</strong>
                </div>
              </div>
              <table
                v-if="deposits.length && loading === false"
                class="table table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr class="bg-gray-100 text-left">
                    <th>
                      <b-form-checkbox
                        v-model="all_deposits_selected"
                        size="lg"
                        :value="true"
                        :unchecked-value="false"
                        @change="selectAll"
                      >
                      </b-form-checkbox>
                    </th>
                    <th></th>
                    <th>Vrijeme pražnjenja</th>
                    <th class="">{{ $t('DEPOSITS.DEVICE_NAME') }}</th>
                    <th>{{ $t('GENERAL.LOCATION') }}</th>
                    <th
                      v-if="
                        currentUserRole === 'ROLE_ADMIN' ||
                        currentUserRole === 'ROLE_WORKER'
                      "
                    >
                      {{ $t('DEPOSITS.DEPOSIT') }}
                    </th>
                    <th>{{ $t('DEPOSITS.REPORTED') }}</th>
                    <th
                      v-if="
                        currentUserRole === 'ROLE_ADMIN' ||
                        currentUserRole === 'ROLE_WORKER'
                      "
                    >
                      {{ $t('DEPOSITS.DIFFERENCE') }}
                    </th>
                    <th class="text-left pr-6"    v-if="
                        currentUserPersonalInfo.email.includes(
                          '@arsfutura.co'
                        ) 
                        ||
                        currentUserPersonalInfo.email.includes('davor.botincan')
                      ">TOKEN AMOUNT</th>
                    <th class="text-left pr-6"    v-if="
                        currentUserPersonalInfo.email.includes(
                          '@arsfutura.co'
                        ) 
                        ||
                        currentUserPersonalInfo.email.includes('davor.botincan')
                      ">REPORTED TOKEN AMOUNT</th>
                    <th class="text-right pr-6">{{ $t('GENERAL.USER') }}</th>

                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="(item, key) in deposits" :key="key">
                    <td>
                      <b-form-checkbox
                        v-if="item.reportedAmount && !item.isVerified"
                        v-model="selected_deposits"
                        :checked="selected_deposits.includes(item.id)"
                        size="lg"
                        :value="item.id"
                        @change="selectSingle"
                      >
                      </b-form-checkbox>
                    </td>
                    <td>
                      <span
                        v-if="item.isVerified"
                        class="label label-sm label-success p-3"
                      ></span>
                      <span
                        v-else-if="item.reportedAmount"
                        class="label label-sm label-warning p-3"
                      ></span>
                      <span
                        v-else
                        class="label label-sm label-danger p-3"
                      ></span>
                    </td>
                    <td>{{ item.createdAt | formatDateTime }}</td>
                    <td class="font-weight-bolder">
                      {{ item.device.name }}
                      <span
                        class="label label-sm label-light-primary label-inline mr-2"
                        >{{ item.device.bsl }}</span
                      >
                      <span
                        class="label label-sm label-light-primary label-inline mr-2"
                        >{{ item.device.tags }}</span
                      >
                    </td>
                    <td>{{ item.location.name }}</td>
                    <td
                      v-if="
                        currentUserRole === 'ROLE_ADMIN' ||
                        currentUserRole === 'ROLE_WORKER'
                      "
                      class="text-right"
                    >
                      {{ item.amount }}
                    </td>
                    <td class="text-left">{{ item.reportedAmount }}</td>
                    <td
                      v-if="
                        currentUserRole === 'ROLE_ADMIN' ||
                        currentUserRole === 'ROLE_WORKER'
                      "
                      class="text-right"
                    >
                      <span v-html="calcDiff(item)"></span>
                    </td>
                    <td
                      v-if="
                        currentUserPersonalInfo.email.includes(
                          '@arsfutura.co'
                        ) ||
                        currentUserPersonalInfo.email.includes('davor.botincan')
                      "
                    >
                      {{ item.tokenAmount || 0 }}
                    </td>
                    <td
                      v-if="
                        currentUserPersonalInfo.email.includes(
                          '@arsfutura.co'
                        ) 
                        ||
                        currentUserPersonalInfo.email.includes('davor.botincan')
                      "
                    >
                      {{ item.reportedTokenAmount || 0 }}
                    </td>
                    <td class="text-right pr-6">{{ item.user.name }}</td>
                    <th class="d-flex">
                      <button
                        v-if="!item.isVerified"
                        @click="reportAmountModal(item.id, key)"
                        class="btn btn-light-primary mr-2 text-center"
                      >
                        €
                      </button>
                      <button
                        v-if="item.reportedAmount && !item.isVerified"
                        @click="verifyDepositModal(key)"
                        class="btn btn-light-success text-center"
                      >
                        ✓
                      </button>
                    </th>
                  </tr>
                </tbody>
              </table>
              <div
                v-if="!deposits.length && loading === false"
                class="text-center mt-5 mb-5 pt-5"
              >
                <h4>{{ $t('DEPOSITS.NO_DATA') }}</h4>
              </div>
            </div>
          </div>
        </div>

        <!-- MODAL AMOUNT-->
        <div class="row">
          <div class="col-12">
            <b-modal
              id="change_reported_amount"
              size="lg"
              centered
              hide-header
              hide-footer
            >
              <div class="card-body pt-7">
                <!--                <b-button-->
                <!--                    class="btn btn-light-primary mb-3"-->
                <!--                    block-->
                <!--                    @click="$bvModal.hide('change_reported_amount')"-->
                <!--                >Close-->
                <!--                </b-button>-->

                <div class="form-group mb-0">
                  <label for="depositAmount" class="font-weight-bolder">{{
                    $t('DEPOSITS.REPORTED_AMOUNT')
                  }}</label>
                  <div class="input-group input-group-solid">
                    <input
                      v-model="reportAmountValue"
                      type="number"
                      class="form-control form-control-solid"
                    />
                  </div>
                </div>
                <div
                  class="form-group mt-5"
                  v-if="
                    currentUserPersonalInfo.email.includes('@arsfutura.co') ||
                    currentUserPersonalInfo.email.includes('@kidsdelux.hr')
                  "
                >
                  <label for="depositAmount" class="font-weight-bolder"
                    >Token reported amount</label
                  >
                  <div class="input-group input-group-solid">
                    <input
                      v-model="reportedTokenAmount"
                      type="number"
                      class="form-control form-control-solid"
                    />
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <button
                  @click.prevent="reportAmount"
                  type="button"
                  class="btn btn-primary btn-lg btn-block"
                >
                  {{ $t('DEPOSITS.REPORT_AMOUNT') }}
                </button>
              </div>
            </b-modal>
          </div>
        </div>

        <!-- MODAL VERIFY-->
        <div class="row">
          <div class="col-12">
            <b-modal
              id="verify_amount"
              size="lg"
              centered
              hide-header
              hide-footer
            >
              <div class="card-body pt-7">
                <!--                <b-button-->
                <!--                    class="btn btn-light-primary mb-3"-->
                <!--                    block-->
                <!--                    @click="$bvModal.hide('verify_amount')"-->
                <!--                >Close-->
                <!--                </b-button>-->

                <div v-if="verifyDepositObj" class="font-size-lg text-center">
                  {{ $t('DEPOSITS.VERIFY_CONFIRM') }}
                  <br />
                  <b>{{ verifyDepositObj.createdAt | formatDateTime }}</b>
                  <br />
                  <b>{{ verifyDepositObj.device.name }}</b
                  >, <br />
                  {{ $t('GENERAL.AMOUNT') }}:
                  <b>{{ verifyDepositObj.reportedAmount }}</b>
                </div>
              </div>
              <div class="card-footer">
                <button
                  @click.prevent="verifyDeposit"
                  type="button"
                  class="btn btn-primary btn-lg btn-block"
                >
                  {{ $t('DEPOSITS.VERIFY_DEPOSIT') }}
                </button>
              </div>
            </b-modal>
          </div>
        </div>

        <!-- MODAL VERIFY ALL-->
        <div class="row">
          <div class="col-12">
            <b-modal id="verify_all" size="lg" centered hide-header hide-footer>
              <div class="card-body pt-7">
                <!--                <b-button-->
                <!--                    class="btn btn-light-primary mb-3"-->
                <!--                    block-->
                <!--                    @click="$bvModal.hide('verify_amount')"-->
                <!--                >Close-->
                <!--                </b-button>-->

                <div class="font-size-lg text-center">
                  {{ $t('DEPOSITS.VERIFY_MULTIPLE_CONFIRM') }}
                  <b>{{ verify_deposits.length }}</b>
                  {{ $t('DEPOSITS.DEPOSITS') }}? <br />
                  <b
                    >{{ range.start | formatDate }} -
                    {{ range.end | formatDate }}</b
                  >
                </div>
              </div>
              <div class="card-footer">
                <button
                  @click.prevent="verifyAllDeposits"
                  type="button"
                  class="btn btn-primary btn-lg btn-block"
                >
                  {{ $t('DEPOSITS.VERIFY_SELECTED') }}
                </button>
              </div>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import 'vue-select/src/scss/vue-select.scss';

.form-group {
  margin-bottom: 0.5rem;
}

.form-control {
  &--logger-select,
  &--modules-select,
  &--users-select {
    height: unset !important;

    .vs__dropdown-toggle {
      padding: 0;
      border: 0;
      border-radius: 0;
    }

    .vs__dropdown-menu {
      border: 1px solid #e4e6ef;
      -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
      box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      li {
        padding: 0.65rem 1rem;

        &:hover {
          background: #f3f6f9;
          color: #3f4254;
        }
      }
    }

    .vs__clear {
      display: none;
    }

    .vs__selected {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      margin: 0.1rem 0.4rem 0.1rem 0;
      position: relative;
      float: left;
      color: #3f4254;
      background: #f3f6f9;
      border: 0;
      border-radius: 0.42rem;
    }

    .vs__dropdown-option--highlight {
      background: #ebedf3;
      color: #3f4254;
    }
  }
}
</style>
